<template lang='pug'>
	.drivinginiceland
		.header
			.content.constrainer
				img.header-image(src='@/assets/jpg/summer.jpg')
				h1.title Driving in Iceland.
				.dashes
					.dash(v-for='n in 5')
				.para.fs Get to know general information about driving in Iceland, such as the speed limit, signs, fines and information about highland driving
		.body-section.gutter.constrainer
			//- .section-pad
			//- 	BookBanner(
			//- 		:bannerNumber="9"
			//- 	)
			.bluecarrental-banner
				BlueCarRentalBanner(
					:image="{ url: require('@/assets/bluecarrental/image5.jpg') }"
					text="We encourage our visitors to book and rent their car in Iceland with Blue Car Rental, safe and reliable cars with the best service possible."
					text-align="left"
				)
			//- .space-driving-in-one.main-desktop
			//- 	.adspace
			//- 		Adspace(:key='$store.state.keydex + 9' :id='661')
			//-.space-driving-in-one.main-mobile
				.adspace
					Adspace(:key='$store.state.keydex + 900' :id='674')
			.before-you-head-out
				h1.title Before you head out.
				.dashes
					.dash(v-for='n in 5')
				.para.fs Before you start your journey on the Icelandic roads, you should consider few things.
				.checklist
					.item.clickable(v-for='item in checkList' @click='item.check = !item.check')
						.check(:class='{ checked: item.check }')
							.checkmark
								img(src='@/assets/check.svg')
						.text {{ item.text }}
			//- .bluecarrental-banner
			//- 	BlueCarRentalBanner(
			//- 		:image="{ url: require('@/assets/bluecarrental/image5.jpg') }"
			//- 		text="We encourage our visitors to book and rent their car in Iceland with Blue Car Rental, safe and reliable cars with the best service possible."
			//- 		text-align="left"
			//- 	)
			.speed-limit
				h1.title Speed limit in Iceland.
				.dashes
					.dash(v-for='n in 5')
				.para.fs Please take note of signs indicating the speed limit for a given road. The traffic signs indicate the maximum speed, but always drive slower if road and/or weather conditions are bad.
				.para.fs The following general rules apply regarding the maximum speed in Iceland, but be aware there are numerous exceptions.
				.icon-set
					.item
						img.image(src='@/assets/signs/50km.svg')
						.text.fs Populated areas
					.item
						img.image(src='@/assets/signs/80km.svg')
						.text.fs Gravel roads maximum speed
					.item
						img.image(src='@/assets/signs/90km.svg')
						.text.fs Highways maximum speed
			//- .space-driving-in-two.main-desktop
			//- 	.adspace
			//- 		Adspace(:key='$store.state.keydex + 10' :id='662')
			//-.space-driving-in-two.main-mobile
				.adspace
					Adspace(:key='$store.state.keydex + 1000' :id='675')
			.road-signs
				.left
					h1.title Road signs in Iceland.
					.dashes
						.dash(v-for='n in 5')
					.para.fs Get to know the special Icelandic traffic signs.
				.right
					img(src='@/assets/jpg/roadsigns.jpg')
			.road-sign-types
				.selection
					.item(v-for='item, index in signTypes' :class='{ checked: item.check, mbot: index === 3 || index === 4 }')
						.text.clickable(@click='selectType(index)') {{ item.text }}
				.para.fs {{ selectedType.para }}
				.tems(:class='{ single: selectedType.text === "Road", inactive: showPopup }')
					.tem(v-for='sign in selectedType.signs')
						img.clickable(:src='sign.url' @click='viewSign(sign)')
						.text.clickable(@click='viewSign(sign)') {{ sign.title }}
				.popup(:class='{ active: showPopup, single: selectedType.text === "Road" }')
					.overlay
					.inner(v-click-outside='closePopup')
						img.close.clickable(src='@/assets/close.svg' @click='closePopup')
						img.image(:src='popupData.url')
						.title {{ popupData.title }}
						.text {{ popupData.text }}
			//- .space-driving-in-three.main-desktop
			//- 	.adspace
			//- 		Adspace(:key='$store.state.keydex + 11' :id='663')
			//-.space-driving-in-three.main-mobile
				.adspace
					Adspace(:key='$store.state.keydex + 1100' :id='676')
			.section-pad
				BlueCarRentalBanner(
					:image="{ url: require('@/assets/bluecarrental/image1.jpg') }"
					text="Safe.is recommends Blue Car Rental for booking and renting safe, reliable cars with the best service possible. Driving around our beautiful island relies heavily on your rental car."
					text-align="left"
				)
			.fines
				.left
					h1.title Fines.
					.dashes
						.dash(v-for='n in 5')
					.para.fs Driving in Iceland is an exciting experience but there are many rules to follow and breaking some of them carries a penalty. Always obey the speed limits, take care to park in a safe place and don’t go off road.  Here we cover the fines associated with breaking some of these rules. These are guidelines and your experience may vary.
				.right
					img(src='@/assets/jpg/fines.jpg')
			.fine-types
				.selection
					.item(v-for='item, index in fineTypes' :class='{ checked: item.check }')
						.text.clickable(@click='selectFine(index)') {{ item.text }}
				.para.fs {{ selectedFine.para }}
				.tems
					.tem.head.desktop
						.left Offence
						.right Fine
					.tem(v-for='fine in selectedFine.content')
						.left {{ fine.type }}
						.right {{ fine.fine }}
			.highland-driving
				h1.title Highland driving.
				.dashes
					.dash(v-for='n in 5')
				.para.fs Highland driving is very popular during the suxmmertime. However it requires a very specific set of skills and vehicles. Conditions can change even more rapidly than on lowlands and roads often require you to cross unbridged rivers.  can be rough and you may not reach speeds much over 40 km/h so make sure you plan for enough time. Because of this it’s extremely important to gather information about the area you are travelling over and leave a detailed Travel Plan at safetravel.is.
				.para.fs Only travel with a 4x4 vehicle and plan your trip carefully. A normal city car will not fare well in the highlands.
				.para.fs.bold Driving in the highlands will inevitably lead you to a river crossing. Keep the following things in mind:
				.notes
					.note
						.left
							.dash(v-for='n in 3')
						.right
							.para.fs There is no insurance that covers any damage that can be lead to crossing rivers or any body of water. The risk is yours and damages can cost thousands of euros/dollars.
					.note
						.left
							.dash(v-for='n in 3')
						.right
							.para.fs Do not cross a river unless you are a 100% sure you will be able to get to the other side – if you are unsure, wait for the next car to come by and discuss if you can go or not.
					.note
						.left
							.dash(v-for='n in 3')
						.right
							.para.fs The current and depth of the rivers changes rapidly according to glacial melt and precipitation. Keep in mind that rivers can become impassable after a heavy rainfall.
					.note
						.left
							.dash(v-for='n in 3')
						.right
							.para.fs Be sure of the route you are going to take before entering the water.
					.note
						.left
							.dash(v-for='n in 3')
						.right
							.para.fs Put your car in a low-gear and cross the river slowly (5 km/h) and steady – do not switch gears while in the water!
					.note
						.left
							.dash(v-for='n in 3')
						.right
							.para.fs Always try to go down stream as going against the current will increase the risk of water going into your engine.
					.note
						.left
							.dash(v-for='n in 3')
						.right
							.para.fs Even the smallest of rivers have lead to some costly damages simply because of the drivers choosing the wrong routes or going too fast.
					.note
						.left
							.dash(v-for='n in 3')
						.right
							.para.fs Do NOT enter the river where it seems calm – this is a sign of depth and is not the ideal spot to cross.
					.note
						.left
							.dash(v-for='n in 3')
						.right
							.para.fs Do not be fooled if large modified vehicles make the trip. They can cross the rivers at higher speeds because they are equipped with a snorkel – allowing the air intake of the engine to be at a higher point than on normal vehicles. Make sure you know the capabilities of your vehicle.
			//- .space-driving-in-four.main-desktop
			//- 	.adspace
			//- 		Adspace(:key='$store.state.keydex + 12' :id='664')
			//-.space-driving-in-four.main-mobile
				.adspace
					Adspace(:key='$store.state.keydex + 1200' :id='677')
			//- .bluecarrental-banner
			//- 	BlueCarRentalBanner(
			//- 		:image="{ url: require('@/assets/bluecarrental/image6.jpg') }"
			//- 	)
			.section-pad
				BlueCarRentalBanner(
					:image="{ url: require('@/assets/bluecarrental/image1.jpg') }"
					text="Safe.is recommends Blue Car Rental for booking and renting safe, reliable cars with the best service possible. Driving around our beautiful island relies heavily on your rental car."
					text-align="left"
				)
			.faq-section
				FAQSection
</template>

<script>
import ClickOutside from 'vue-click-outside';
export default {
	name: 'DrivingInIceland',
	metaInfo: {
		title: 'Safe.is - Driving in Iceland.',
		meta: [
			{
				name: 'og:site_name',
				property: 'og:site_name',
				content: 'Safe.is'
			},
			{
				name: 'og:url',
				property: 'og:url',
				content: 'https://safe.is/driving-in-iceland/index.html'
			},
			{
				name: 'og:image',
				property: 'og:image',
				content: 'https://safe.is/interesting/summer.jpg'
			},
			{
				name: 'og:title',
				property: 'og:title',
				content: 'Safe.is - Driving in Iceland.'
			},
			{
				name: 'og:description',
				property: 'og:description',
				content: 'Get to know information about driving in Iceland, such as the speed limit, signs, fines and information about highland driving.'
			},
			{
				name: 'description',
				property: 'description',
				content: 'Get to know information about driving in Iceland, such as the speed limit, signs, fines and information about highland driving.'
			}
		]
	},
	directives: {
		ClickOutside
	},
	data: function () {
		return {
			media: {},
			showPopup: false,
			popupData: {
				url: '/signs/no_entry.svg',
				title: 'No entry',
				text: 'This sign means no entry. Meaning that if you are driving on a road and seeing this sign you are probably going the wrong way.'
			},
			fineTypes: [
				{
					check: true,
					text: 'General',
					para: 'There are rules of conduct on the roads of Iceland to ensure the safety of yourself and others on the road. Violating these rules can incur a fine.',
					content: []
				},
				{
					check: false,
					text: 'Speed',
					para: 'Iceland has speed limits and police precincts around Iceland measure the speed of drivers with hidden cameras and police cars.  It is best to keep to the legal limit, even if you feel traffic is moving faster. The fines can be substantial and vary between zones. Here are some examples of fines for speeding.',
					content: []
				},
				{
					check: false,
					text: 'Parking',
					para: 'You can be fined for either parking illegally where you are not allowed to park a vehicle or for parking on a designated parking space without paying.',
					content: []
				},
				{
					check: false,
					text: 'Off road',
					para: 'Do not drive off road. Even if you think, it is worth the risk of a fine, off road driving seriously damages a fragile landscape and it takes decades to recover.',
					content: []
				}
			],
			signTypes: [
				{
					check: false,
					text: 'Restriction',
					para: 'Restriction signs are circle shaped and indicate restrictions which have been placed on the road or street you are driving on. These can be outright ban on traffic or simple speed limits.',
					signs: []
				},
				{
					check: true,
					text: 'Warning',
					para: 'Warning signs are meant to indicate dangers requiring special attention and care. They are usually triangular and often accompanied with Icelandic or English text in a box shaped sign.',
					signs: []
				},
				{
					check: false,
					text: 'Information',
					para: 'Information signs are rectangular in blue and white colors. They usually indicate where you can find emergency services, hospitals, pharmacies, police and emergency shelters but can also indicate important places and museums.',
					signs: []
				},
				{
					check: false,
					text: 'Road',
					para: 'There are many road signs on intersections of roads, showing where the road may take you, the number of the road and how far it is to the specified destination.',
					signs: []
				},
				{
					check: false,
					text: 'Icelandic',
					para: ' There are many signs with Icelandic texts on them which may be confusing. But if they are red and yellow it is good to take special note of them and understand their message.',
					signs: []
				}
			],
			checkList: [
				{
					check: false,
					text: ' Have you checked the weather and road conditions?'
				},
				{
					check: false,
					text: 'Are your tires appropriate for the road conditions you will be driving in?'
				},
				{
					check: false,
					text: 'Are all lights functioning?'
				},
				{
					check: false,
					text: 'Do you have enough wiper fluid?'
				},
				{
					check: false,
					text: 'Do you have a ice scraper?'
				},
				{
					check: false,
					text: 'Do you have enough gas for the planned trip?'
				},
				{
					check: false,
					text: 'Do you know where gas stations are located near your route?'
				},
				{
					check: false,
					text: 'Is your phone charged?'
				},
				{
					check: false,
					text: 'Do you have supplies to last the trip?'
				},
				{
					check: false,
					text: 'Do you have weather appropriate clothes?'
				}
			]
		};
	},
	computed: {
		selectedType: function () {
			let sign = {};
			for (let i = 0; i < this.signTypes.length; i++) {
				if (this.signTypes[i].check) {
					sign = this.signTypes[i];
				}
			}
			return sign;
		},
		selectedFine: function () {
			let fine = {};
			for (let i = 0; i < this.fineTypes.length; i++) {
				if (this.fineTypes[i].check) {
					fine = this.fineTypes[i];
				}
			}
			return fine;
		}
	},
	mounted: function () {
		this.$store.state.keydex += 1;
	},
	created: function () {
		this.axios.get('https://us-central1-safe-3ee55.cloudfunctions.net/roadSigns').then((result) => {
			this.signTypes[0].signs = result.data[0].signs;
			this.signTypes[1].signs = result.data[1].signs;
			this.signTypes[2].signs = result.data[2].signs;
			this.signTypes[3].signs = result.data[3].signs;
			this.signTypes[4].signs = result.data[4].signs;
		});
		this.axios.get('https://us-central1-safe-3ee55.cloudfunctions.net/fines').then((result) => {
			this.fineTypes[0].content = result.data[0].content;
			this.fineTypes[1].content = result.data[1].content;
			this.fineTypes[2].content = result.data[2].content;
			this.fineTypes[3].content = result.data[3].content;
		});
	},
	methods: {
		closePopup: function () {
			this.showPopup = false;
		},
		viewSign: function (item) {
			this.popupData = {
				title: item.title,
				text: item.text,
				url: item.url
			};
			setTimeout(() => {
				this.showPopup = true;
			}, 100);
		},
		selectType: function (index) {
			for (let i = 0; i < this.signTypes.length; i++) {
				if (index === i) {
					this.signTypes[i].check = true;
				} else {
					this.signTypes[i].check = false;
				}
			}
		},
		selectFine: function (index) {
			for (let i = 0; i < this.fineTypes.length; i++) {
				if (index === i) {
					this.fineTypes[i].check = true;
				} else {
					this.fineTypes[i].check = false;
				}
			}
		}
	}
};
</script>

<style lang='less' scoped>
	.drivinginiceland {
		.space-driving-in-one {
			min-height: 1px;
			&.main-mobile {
				display: none;
				margin-top: 48px;
				margin-bottom: 48px;
			}
			&.main-desktop {
				margin-top: 96px;
				margin-bottom: 96px;
			}
			@media screen and (max-width: 768px) {
				&.main-mobile {
					display: block;
				}
				&.main-desktop {
					// display: none;
				}
			}
		}
		.space-driving-in-two {
			&.main-mobile {
				display: none;
				margin-bottom: 48px;
			}
			&.main-desktop {
				margin-top: 96px;
				margin-bottom: 96px;
			}
			@media screen and (max-width: 768px) {
				&.main-mobile {
					display: block;
				}
				&.main-desktop {
					// display: none;
				}
			}
		}
		.space-driving-in-three {
			min-height: 1px;
			&.main-mobile {
				display: none;
				margin-top: 48px;
				margin-bottom: 48px;
			}
			&.main-desktop {
				margin-top: 96px;
				margin-bottom: 96px;
			}
			@media screen and (max-width: 768px) {
				&.main-mobile {
					display: block;
				}
				&.main-desktop {
					// display: none;
				}
			}
		}
		.space-driving-in-four {
			&.main-mobile {
				display: none;
				margin-top: 48px;
				margin-bottom: 48px;
			}
			&.main-desktop {
				margin-top: 96px;
				margin-bottom: 96px;
			}
			@media screen and (max-width: 768px) {
				&.main-mobile {
					display: block;
				}
				&.main-desktop {
					// display: none;
				}
			}
		}
		.body-section {
			.faq-section {
				margin-top: 192px;
				margin-bottom: 237px;
			}
			.highland-driving {
				margin-top: 64px;
				text-align: left;
				.notes {
					padding-top: 48px;
					.note {
						display: grid;
						grid-template-columns: 129px auto;
						@media screen and (max-width: 1220px) {
							grid-template-columns: 85px auto;
						}
						.left {
							display: grid;
							grid-template-columns: 1fr 1fr 1fr;
							grid-column-gap: 2px;
							max-width: 50px;
							margin-left: auto;
							margin-right: auto;
							.dash {
								background-color: #8FFAE3;
								width: 12px;
								height: 2px;
							}
						}
						.right {
							.para {
								max-width: 894px;
								transform: translate3d(0px, -13px, 0px);
							}
						}
					}
				}
				.title {
					text-align: left;
					max-width: 800px;
				}
				.dashes {
					margin-bottom: 47px;
				}
				.para {
					padding-bottom: 34px;
					&.heading {
						margin: 0px;
						color: #000000;
						font-size: 24px;
						font-weight: bold;
						letter-spacing: 2px;
						line-height: 36px;
					}
				}
				.bold {
					font-weight: bold;
				}
			}
			.fine-types {
				padding-top: 112px;
				.tems {
					.tem {
						display: grid;
						grid-template-columns: 1fr 1fr;
						padding-bottom: 41px;
						grid-column-gap: 96px;
						@media screen and (max-width: 1220px) {
							grid-template-columns: 1fr;
							grid-template-areas:
							'top'
							'bottom';
						}
						.left {
							text-align: left;
							font-size: 24px;
							font-weight: normal;
							font-style: normal;
							font-stretch: normal;
							line-height: 1.42;
							letter-spacing: 2px;
							color: #000000;
							@media screen and (max-width: 1220px) {
								grid-area: bottom;
								font-size: 16px;
								line-height: 1.63;
								letter-spacing: 1.3px;
							}
						}
						.right {
							text-align: right;
							font-size: 24px;
							font-weight: bold;
							font-style: normal;
							font-stretch: normal;
							line-height: 1.5;
							letter-spacing: 2px;
							text-align: right;
							color: #000000;
							@media screen and (max-width: 1220px) {
								text-align: left;
								grid-area: top;
								font-size: 19px;
								line-height: 1.37;
								letter-spacing: 1.6px;
							}
						}
						&.head {
							padding-bottom: 48px;
							.left {
								width: 96px;
								height: 51px;
								font-size: 18px;
								font-weight: normal;
								font-style: normal;
								font-stretch: normal;
								line-height: 2.43;
								letter-spacing: 1.8px;
								color: #cccccc;
							}
							.right {
								font-size: 18px;
								font-weight: normal;
								font-style: normal;
								font-stretch: normal;
								line-height: 2.43;
								letter-spacing: 1.8px;
								text-align: right;
								color: #cccccc;
							}
						}
					}
				}
				.para {
					margin-left: auto;
					margin-right: auto;
					max-width: 843px;
					margin-bottom: 86px;
					font-family: Montserrat;
					font-size: 18px;
					font-weight: normal;
					font-style: normal;
					font-stretch: normal;
					line-height: 1.62;
					letter-spacing: 1.8px;
					text-align: center;
					color: #000000;
				}
				.selection {
					display: grid;
					grid-template-columns: auto auto auto auto;
					@media screen and (max-width: 1220px) {
						// grid-template-columns: auto auto auto;
					}
					.item {
						min-height: 87px;
						.text {
							display: inline-block;
							margin-left: auto;
							margin-right: auto;
							height: 29px;
							padding-bottom: 10px;
							font-size: 24px;
							font-weight: bold;
							font-style: normal;
							font-stretch: normal;
							line-height: normal;
							letter-spacing: 1px;
							text-align: center;
							color: #cccccc;
							border-bottom: 3px solid rgba(0, 0, 0, 0);
							transition: .3s;
							transform: translate3d(0, 0, 0, 0);
							@media screen and (max-width: 1220px) {
								font-size: 18px;
							}
							@media screen and (max-width: 500px) {
								font-size: 12px;
							}
						}
						&.checked {
							.text {
								padding-bottom: 4px;
								color: black;
								border-bottom: 3px solid #f9db49;
							}
						}
					}
				}
			}
			.fines {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-column-gap: 48px;
				margin-top: 192px;
				@media screen and (max-width: 1220px) {
					grid-template-columns: 1fr;
					grid-template-areas:
					'top'
					'bottom';
				}
				.right {
					@media screen and (max-width: 1220px) {
						grid-area: top;
						margin-bottom: 20px;
					}
					img {
						width: 100%;
						max-height: 432px;
						object-fit: cover;
					}
				}
				.left {
					@media screen and (max-width: 1220px) {
						grid-area: bottom;
					}
					.title {
						text-align: left;
						max-width: 344px;
						color: #000000;
					}
					.para {
						text-align: left;
						max-width: 624px;
					}
					.dashes {
						margin-bottom: 47px;
					}
				}
			}
			.road-sign-types {
				padding-top: 112px;
				@media screen and (max-width: 768px) {
					padding-top: 28px;
				}
				.popup {
					pointer-events: none;
					.overlay {
						position: absolute;
						top: 0px;
						left: 0px;
						right: 0px;
						bottom: 0px;
						background-color: rgba(0,0,0,0.15);
					}
					.inner {
						padding-top: 96px;
						padding-bottom: 96px;
						top: 50%;
						left: 50%;
						transform: translate3d(-50%, -50%, 0px) scale(.5);
						position: fixed;
						max-width: 536px;
						width: 100%;
						padding-bottom: 96px;
						background-color: #FFFFFF;
						box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
						transition: .3s;
						@media screen and (max-width: 968px) {
							padding-top: 24px;
							padding-bottom: 24px;
						}
						.close {
							position: absolute;
							top: 16px;
							right: 16px;
						}
						.image {
							margin-bottom: 64px;
							width: 220px;
							margin-left: auto;
							margin-right: auto;
							@media screen and (max-width: 968px) {
								margin-bottom: 24px;
								width: 110px;
							}
						}
						.title {
							margin-bottom: 24px;
							color: #000000;
							font-weight: bold;
							font-family: Montserrat;
							font-size: 21px;
							letter-spacing: 2.49px;
							line-height: 32px;
							text-align: center;
							margin-left: auto;
							margin-right: auto;
							@media screen and (max-width: 968px) {
								margin-bottom: 12px;
								font-size: 18px;
							}
						}
						.text {
							margin-left: auto;
							margin-right: auto;
							max-width: 404px;
							color: #000000;
							font-family: Montserrat;
							font-size: 21px;
							letter-spacing: 2.49px;
							line-height: 32px;
							text-align: center;
							@media screen and (max-width: 968px) {
								font-size: 16px;
							}
						}
					}
					transition: .3s;
					opacity: 0;
					&.active {
						opacity: 1;
						pointer-events: initial;
						.inner {
							transform: translate3d(-50%, -50%, 0px) scale(.95);
						}
					}
					&.single {
						.inner {
							.image {
								@media screen and (max-width: 968px) {
									width: 80%;
								}
							}
							.title {
								margin-left: auto;
								margin-right: auto;
								max-width: 404px;
								color: #000000;
								font-weight: normal;
								font-family: Montserrat;
								font-size: 21px;
								letter-spacing: 2.49px;
								line-height: 32px;
								text-align: center;
								@media screen and (max-width: 968px) {
									font-size: 16px;
								}
							}
						}
					}
				}
				.tems {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					@media screen and (max-width: 782px) {
						grid-template-columns: 1fr 1fr;
					}
					@media screen and (max-width: 552px) {
						grid-template-columns: 1fr;
					}
					grid-row-gap: 55px;
					&.inactive {
						pointer-events: none;
					}
					.tem {
						img {
							width: 100%;
							max-height: 97px;
							object-fit: contain;
						}
						.text {
							margin-top: 46px;
							margin-left: auto;
							margin-right: auto;
							max-width: 244px;
							height: 90px;
							font-family: Montserrat;
							font-size: 30px;
							font-weight: normal;
							font-style: normal;
							font-stretch: normal;
							line-height: 1.5;
							letter-spacing: 2.5px;
							text-align: center;
							color: #000000;
							@media screen and (max-width: 1220px) {
								font-size: 18px;
							}
							@media screen and (max-width: 375px) {
								font-size: 16px;
							}
						}
					}
					&.single {
						grid-template-columns: 1fr;
						.tem {
							.text {
								margin-top: 95px;
								width: 100%;
								max-width: 960px;
							}
						}
					}
				}
				.para {
					margin-left: auto;
					margin-right: auto;
					max-width: 843px;
					margin-bottom: 156px;
					text-align: center;
					@media screen and (max-width: 768px) {
						margin-bottom: 48px;
					}
				}
				.selection {
					display: grid;
					grid-template-columns: auto auto auto auto auto;
					@media screen and (max-width: 1220px) {
						grid-template-columns: auto auto auto;
					}
					.item {
						min-height: 87px;
						@media screen and (max-width: 1220px) {
							min-height: 47px;
							&.mbot {
								transform: translate3d(50%, 0px, 0px);
								width: 30vw;
							}
						}
						.text {
							display: inline-block;
							margin-left: auto;
							margin-right: auto;
							padding-bottom: 10px;
							font-size: 24px;
							font-weight: bold;
							font-style: normal;
							font-stretch: normal;
							line-height: normal;
							letter-spacing: 1px;
							text-align: center;
							color: #cccccc;
							border-bottom: 3px solid rgba(0, 0, 0, 0);
							transition: .3s;
							transform: translate3d(0, 0, 0, 0);
							@media screen and (max-width: 1220px) {
								font-size: 18px;
							}
							@media screen and (max-width: 500px) {
								font-size: 12px;
							}
						}
						&.checked {
							.text {
								padding-bottom: 4px;
								color: black;
								border-bottom: 3px solid #f9db49;
							}
						}
					}
				}
			}
			.road-signs {
				display: grid;
				grid-template-columns: 1fr 1fr;
				@media screen and (max-width: 1220px) {
					grid-template-columns: 1fr;
					grid-template-areas:
					'top'
					'bottom';
				}
				.right {
					@media screen and (max-width: 1220px) {
						grid-area: top;
						margin-bottom: 20px;
					}
					img {
						width: 100%;
						max-height: 432px;
						object-fit: cover;
					}
				}
				.left {
					@media screen and (max-width: 1220px) {
						grid-area: bottom;
					}
					.dashes {
						margin-bottom: 47px;
					}
					.para {
						padding-bottom: 37px;
						text-align: left;
						max-width: 382px;
					}
					.title {
						text-align: left;
						max-width: 344px;
					}
				}
			}
			.speed-limit {
				padding-top: 128px;
				padding-bottom: 128px;
				@media screen and (max-width: 768px) {
					padding-top: 63px;
				}
				.icon-set {
					padding-top: 57px;
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
					grid-column-gap: 146px;
					@media screen and (max-width: 700px) {
						grid-template-columns: 1fr 1fr;
					}
					@media screen and (max-width: 420px) {
						grid-template-columns: 1fr;
						grid-row-gap: 48px;
					}
					.item {
						.image {
							max-width: 96px;
							margin-bottom: 48px;
							width: 100%;
						}
						.text {
							margin-left: auto;
							margin-right: auto;
							max-width: 243px;
							text-align: center;
						}
					}
				}
				.dashes {
					margin-bottom: 47px;
				}
				.para {
					padding-bottom: 37px;
					text-align: left;
					max-width: 850px;
				}
				.title {
					text-align: left;
					max-width: 800px;
				}
			}
			.before-you-head-out {
				margin-top: 192px;
				.checklist {
					padding-top: 56px;
					padding-left: 75px;
					@media screen and (max-width: 1220px) {
						padding-left: 0px;
					}
					.item {
						display: grid;
						grid-template-columns: 54px auto;
						@media screen and (max-width: 1220px) {
							grid-template-columns: 30px auto;
						}
						margin-bottom: 27px;
						.check {
							position: relative;
							transform: rotate3d(1, 1, 0, 180deg);
							display: flex;
							justify-self: center;
							width: 24px;
							height: 24px;
							@media screen and (max-width: 1220px) {
								transform: rotate3d(1, 1, 0, 180deg);
								width: 12px;
								height: 12px;
							}
							border: solid 2px #00476e;
							transition: .3s;
							.checkmark {
								transition: .3s;
								img {
									position: relative;
									left: 5px;
									top: 5px;
									width: 14px;
									opacity: 0;
									transition: .3s;
									@media screen and (max-width: 1220px) {
										width: 8px;
										left: 2px;
										top: -6px;
									}
								}
							}
							&.checked {
								transform: rotate3d(1, 1, 0, 0deg);
								@media screen and (max-width: 1220px) {
									transform: rotate3d(1, 1, 0, 0deg);
								}
								border: solid 2px #8ff7a7;
								.checkmark {
									img {
										opacity: 1;
									}
								}
							}
						}
						.text {
							line-height: 1.62;
							text-align: left;
							width: 100%;
							font-size: 18px;
							letter-spacing: 1.8px;
							color: #000000;
							@media screen and (max-width: 1220px) {
								max-width: 297px;
								font-size: 16px;
								line-height: 1.63;
								letter-spacing: 1.3px;
							}
						}
					}
				}
				.title {
					text-align: left;
					max-width: 800px;
				}
				.para {
					margin-top: 37px;
					text-align: left;
					max-width: 634px;
				}
			}
			.bluecarrental-banner {
				margin-top: 96px;
			}
		}
		.header {
			height: 628px;
			max-height: 628px;
			background-image: url('~@/assets/jpg/drivinginicelandheader.jpg');
			background-size: cover;
			background-position: 50% 100%;
			.header-image {
				display: none;
			}
			@media screen and (max-width: 1220px) {
				background-image: none;
				height: initial;
				max-height: initial;
				.header-image {
					margin-top: 48px;
					display: block;
					width: 100%;
					max-height: 440px;
					object-fit: cover;
					object-position: bottom;
				}
			}
			.content {
				position: relative;
				top: 0px;
				height: 100%;
				left: 0px;
				text-align: left;
				.title {
					padding-top: 68px;
					@media screen and (max-width: 768px) {
						padding-top: 40px;
					}
					position: relative;
					max-width: 374px;
				}
				.para {
					padding-top: 38px;
					max-width: 374px;
				}
				.button {
					display: grid;
					grid-template-columns: 80% 20%;
					height: 48px;
					width: 260px;
					background-color: #FFFFFF;
					box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.07);
					border-left: 4px solid #AF7595;
					margin-top: 58px;
					.text {
						color: black;
						height: 30px;
						width: 161px;
						font-size: 18px;
						font-weight: 600;
						letter-spacing: 1.5px;
						line-height: 48px;
						padding-left: 32px;
					}
					.arrow {
						position: relative;
						top: 50%;
						transform: translate3d(0px, -50%, 0px);
					}
				}
			}
		}
		.dashes {
			margin-top: 38px;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			width: 60px;
			.dash {
				width: 8px;
				height: 2px;
				background-color: #F9DB49;
			}
		}
	}
</style>
